import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  FaGithub,
  FaInstagram,
  FaFacebook,
  FaLinkedinIn
} from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
            I am a highly motivated and results-driven <b className="purple"> Data Engineer </b> with 1+ year of experience in AWS technologies. My expertise lies in <b className="purple"> designing, developing and delivering solutions </b> for products of <b className="purple"> on-cloud datalake platform</b>.
            <br />
            <br />
            With a strong focus on business enablement, I am committed to leveraging my skills and experience in data engineering to drive business decision-making for <b className="purple"> retail banking services </b> and contribute to the success of the organization.

              <br />
              <br />I am fluent in modern programming language like

                <b className="purple"> Python </b> and <b className="purple"> Scala </b>. 

              <br />
              <br />
              Also a <b className="purple"> Direct PhD student </b> in Informatics and Computer Engineering, attempting to incorporate data science and analytics capabilities into healthcare. 🤷‍♂️

              {/* &nbsp; like
              <i>
                <b className="purple"> React.js and Next.js</b>
              </i> */}
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/ladcva"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://facebook.com/ladcva"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaFacebook />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/ladcva/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/ladpdp/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <FaInstagram />
                </a>
              </li>
            </ul>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
